import React, { useCallback, useMemo } from 'react'
import { allBusiness } from 'src/util/selectors'
import { useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'
import VirtualScroll from 'react-dynamic-virtual-scroll'
import { uniqBy } from 'lodash'
import { FaFilterCircleXmark, FaFilter } from 'react-icons/fa6'
import './BusinessList.sass'
import { MANAGEMENT_TYPES } from '../util/constants'

export default function BusinessList({ filter, setFilter, setEstanco, estanco, showInMap }) {
  const business = useSelector(allBusiness)

  const searchableBusiness = useMemo(() => {
    if (!business) return []
    return business
      .filter((v) => !v.desactivado)
      .map((b) => ({
        ...b,
        search: [
          b.nombre ? b.nombre.toLowerCase() : '',
          // b.direccion ? b.direccion.toLowerCase() : '',
          b.nombreEstanco ? b.nombreEstanco.toLowerCase() : '',
          b.id ? `${b.id}`.toLowerCase() : '',
          b.idEstanco ? `${b.idEstanco}`.toLowerCase() : ''
        ].join('@@@')
      }))
  }, [business])

  const getFormattedManagementType = (managementTypeKey) => {
    if (!managementTypeKey) return '-'
    const managementType = MANAGEMENT_TYPES.find((m) => m.key === managementTypeKey)
    return managementType ? managementType.name : '-'
  }

  const sortedBusiness = useMemo(() => {
    if (!searchableBusiness) return []
    return [...searchableBusiness].sort((a, b) => a.nombre.localeCompare(b.nombre))
  }, [searchableBusiness])

  const filtered = useMemo(() => {
    if (!sortedBusiness) return []
    let base = sortedBusiness
    if (estanco) base = base.filter((b) => b.idEstanco === estanco)
    return base.filter((b) => {
      if (!filter) return true
      return b.search.toLowerCase().includes(filter.toLowerCase())
    })
  }, [estanco, filter, sortedBusiness])

  const estancos = useMemo(() => {
    if (!sortedBusiness) return []
    return uniqBy(
      sortedBusiness.map((b) => ({
        nombre: b.nombreEstanco,
        id: b.idEstanco,
        type: 'estanco'
      })),
      'id'
    )
      .filter((a) => a.nombre && a.id && (!estanco || estanco === a.id))
      .sort((a, b) => a.nombre.localeCompare(b.nombre))
  }, [estanco, sortedBusiness])

  const filteredEstancos = useMemo(() => {
    if (!estancos) return []
    return estancos.filter((e) => {
      if (!filter) return true
      if (estanco) return true
      return (
        e.nombre.toLowerCase().includes(filter.toLowerCase()) ||
        e.id.toString().toLowerCase().includes(filter.toLowerCase())
      )
    })
  }, [estanco, estancos, filter])

  const allFiltered = useMemo(() => {
    return [...filteredEstancos, ...filtered]
  }, [filteredEstancos, filtered])

  const handleClickEstanco = useCallback(
    (item) => {
      if (estanco === item.id) {
        setEstanco('')
      } else {
        setEstanco(item.id)
        setFilter('')
      }
    },
    [estanco, setEstanco, setFilter]
  )

  const handleClickBusiness = useCallback(
    (item) => {
      showInMap(item)
    },
    [showInMap]
  )

  const renderEstanco = useCallback(
    (item) => {
      return (
        <div
          className={['estanco', item.id === estanco && 'selected'].join(' ')}
          onClick={() => handleClickEstanco(item)}
        >
          {item.id === estanco ? (
            <div className="estanco__clear" onClick={() => setEstanco('')}>
              <FaFilterCircleXmark />
            </div>
          ) : (
            <div className="estanco__filter">
              <FaFilter />
            </div>
          )}
          <div className="estanco__nombre">{item.nombre}</div>
          <div className="estanco__id">{item.id}</div>
        </div>
      )
    },
    [estanco, handleClickEstanco, setEstanco]
  )

  const renderBusiness = useCallback(
    (item) => {
      return (
        <div className="business" onClick={() => handleClickBusiness(item)}>
          <div className="business__line">
            <div className="business__nombre">
              {item.nombre} <span className="business__id">{item.id}</span>
            </div>
            <div>
              <span className="business__gestion">{getFormattedManagementType(item.tipoGestion)}</span>
            </div>
          </div>
          <div className="business__line">
            <div className="business__address">{item.direccion}</div>
          </div>
        </div>
      )
    },
    [handleClickBusiness]
  )

  const renderRow = useCallback(
    (index) => {
      const item = allFiltered[index]
      if (item.type === 'estanco') {
        return renderEstanco(item)
      }
      return renderBusiness(item)
    },
    [allFiltered, renderBusiness, renderEstanco]
  )

  return (
    <div className="business-list">
      <VirtualScroll
        className="business-list__virtual-scroll"
        minItemHeight={40}
        buffer={100}
        totalLength={allFiltered.length}
        renderItem={renderRow}
      />
    </div>
  )
}
BusinessList.propTypes = {
  filter: PropTypes.string,
  estanco: PropTypes.string,
  setFilter: PropTypes.func,
  setEstanco: PropTypes.func,
  showInMap: PropTypes.func
}
